import {Button, ButtonProps, CircularProgress, Dialog, Typography} from "@barracuda-internal/bds-core";
import React, {useState} from "react";
import DialogBody from "../../dialog/DialogBody/DialogBody";
import Toolbar from "../../layout/Toolbar/Toolbar";
import {makeOverrideableStyles} from "@cuda-react/theme";
import {Theme} from "@mui/material";
import {useTranslation} from "react-i18next";
import {createStyles} from "@mui/styles";

const styles = (theme: Theme) => createStyles({
    root: {},
    confirmMessage: {
        margin: "8px 24px",
        "&:first-of-type": {
            marginTop: "16px"
        },
        "&:last-of-type": {
            marginBottom: "16px"
        }
    },
    formButton: {}
});

const useStyles = makeOverrideableStyles("SaveButton", styles);

export type SaveButtonProps = {
    /** the BDS type of the button */
    bdsType?: ButtonProps["bdsType"],

    /** the confirm message */
    confirmMessage?: string | string[],

    /** if true the button is disabled and a loading spinner is shown */
    disabled?: boolean,

    /** handles the redirect on success action - empty by default */
    handleSubmitWithRedirect?: (options?: any) => (event?: any) => Promise<void>,

    /** label for the button */
    label?: string,

    /** if true the button is disabled since no fields in the form have been modified yet */
    pristine?: boolean,

    /** if true onEnter submits the values */
    submitOnEnter?: boolean
}

/**
 *  Button in order to save the form data
 */
const SaveButton = (props: SaveButtonProps): JSX.Element => {
    const {
        disabled,
        label = "cuda.buttons.save",
        submitOnEnter = true,
        bdsType = "interactiveEmphasis",
        confirmMessage,
        handleSubmitWithRedirect,
        pristine
    } = props;
    const [open, setOpen] = useState(false);
    const classes = useStyles(props);
    const [translate] = useTranslation();
    const performSave = () => handleSubmitWithRedirect?.()();
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (disabled) {
            event.preventDefault();
        } else {
            if (event) {
                event.preventDefault();
            }
            if (confirmMessage) {
                setOpen(true);
            } else {
                performSave();
            }
        }
    };

    const confirmMessageArray = confirmMessage && (Array.isArray(confirmMessage) ? confirmMessage : [confirmMessage]);

    return (
        <React.Fragment>
            <Button
                className={classes.root}
                type={submitOnEnter ? "submit" : "button"}
                startIcon={(disabled ? (
                    <CircularProgress id="cuda-loading-spinner" size={16} thickness={2}/>
                ) : undefined)}
                onClick={handleClick}
                disabled={!!disabled || pristine}
                bdsType={bdsType}
                variant="contained"
                size="small"
            >
                {translate(label)}
            </Button>
            {submitOnEnter ? undefined : (<input type="text" hidden disabled/>)}
            {confirmMessageArray && (
                <Dialog open={open}>
                    <DialogBody
                        title="cuda.save.title"
                        onClose={() => setOpen(false)}
                    >
                        {confirmMessageArray.map((message) => (
                            <Typography key={message} className={classes.confirmMessage}>
                                {translate(message)}
                            </Typography>
                        ))}
                        <Toolbar>
                            <Button
                                bdsType="interactiveEmphasis"
                                onClick={() => {
                                    performSave();
                                    setOpen(false);
                                }}
                                variant="contained"
                                size="small"
                            >
                                {translate("cuda.buttons.confirm")}
                            </Button>
                            <Button
                                className={classes.formButton}
                                bdsType="interactiveNeutral"
                                onClick={() => setOpen(false)}
                                variant="contained"
                                size="small"
                            >
                                {translate("cuda.buttons.cancel")}
                            </Button>
                        </Toolbar>
                    </DialogBody>
                </Dialog>
            )}
        </React.Fragment>
    );
};

export default SaveButton;