// @ts-nocheck
// TODO: Need to think how best to handle this for DataModel etc.
export default <DataModel = any>(url: string, options: RequestInit = {}, isBinary?: boolean): Promise<{body?: DataModel, status?: number, message?: string}> => {
    options.credentials = "same-origin";
    options.headers = {"Accept": "application/json", ...options.headers};

    return fetch(url, options).then((response) => {
        // Simply return empty object for "No Content" responses
        if (response.status === 204) {
            return {body: "", message: "", status: response.status};
        }
        if (isBinary && response.ok) {
            return response.blob().then((data) => ({
                body: data,
                status: response.status
            }));
        }
        // Read either string or json body
        const contentType = response.headers.get("Content-Type");
        if (contentType && contentType.includes("application/json")) {
            return response.json().then((json) => {
                const formattedResponse = {
                    body: json,
                    message: json.message,
                    status: response.status
                };
                if (response.ok) {
                    return formattedResponse;
                }
                throw formattedResponse;
            });
        }
        // Get body for error
        return response.text().then((content) => {
            const formattedResponse = {
                body: content,
                status: response.status
            };
            if (response.ok) {
                return formattedResponse;
            }
            throw formattedResponse;
        });
    })
    .catch((error) => {
        const formattedError = {
            name: error.name,
            code: error.code,
            body: error.body,
            message: error.message,
            status: error.status
        };

        if (error.body && error.body.localizedMessage) {
            formattedError.message = error.body.localizedMessage;
        }

        if (!error.message && error.status !== undefined) {
            formattedError.message = "httpError." + error.status;
        }

        return Promise.reject(formattedError);
    });
};