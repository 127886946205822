import {
    NotificationDisplayType,
    NotificationEventType,
    NotificationLevel,
    PostEvent,
    useCustomEvents,
    useDeepCompareCallback,
    useGlobalParam,
    usePostNotification,
    useRedirect
} from "../../../hooks";
import {isNull, omitBy} from "lodash";

export const AuthEventKey = "AUTH_EVENT";

export enum AuthEventType {
    CHECK = "CHECK",
    ERROR = "ERROR",
    LOGIN = "LOGIN",
    LOGOUT = "LOGOUT",
    CHANGE_ACCOUNT = "CHANGE_ACCOUNT"
}

export type AuthEventCheck = {
    type: AuthEventType.CHECK,
    params?: any
}
export type AuthEventError = {
    type: AuthEventType.ERROR,
    params?: any
}
export type AuthEventLogin = {
    type: AuthEventType.LOGIN,
    params?: any
}
export type AuthEventLogout = {
    type: AuthEventType.LOGOUT,
    params?: any
}
export type AuthEventChangeAccount = {
    type: AuthEventType.CHANGE_ACCOUNT,
    params: {
        accountId: string | number,
        updateInPlace: boolean
    }
}
export type AuthEvent = AuthEventCheck | AuthEventError | AuthEventLogin | AuthEventLogout | AuthEventChangeAccount;

export type AuthClientResolveResponse = {
    redirect?: string,
    loginUrl?: string,
} & Record<string, any>;
export type AuthClient = (type: AuthEventType, params?: AuthEvent["params"], globalParams?: any) => Promise<AuthClientResolveResponse>;

export type AuthProviderProps = {
    authClient: AuthClient | null
};

const getOrigin = (): string | undefined => {
    const reduxRouterPath = window.location.href.substring(window.location.href.indexOf("#") + 1);
    if (reduxRouterPath.startsWith("/login")) {
        return undefined;
    }

    return reduxRouterPath;
};

export const AuthProvider = ({authClient}: AuthProviderProps): null => {
    const [globalParams, setGlobalParams] = useGlobalParam();
    const updateParams = (newParams: any) => {
        setGlobalParams((currentParams: any) => omitBy({
            ...currentParams,
            ...newParams
        }, isNull));
    };
    const postNotification = usePostNotification();
    const performRedirect = useRedirect();

    const eventHandler = useDeepCompareCallback((event?: AuthEvent, postEvent?: PostEvent<AuthEvent>) => {
        if (!event?.type || !authClient) {
            return;
        }

        const eventOrigin = getOrigin();
        authClient(event.type, event.params, globalParams).then((response) => {
            if (!response) return;

            const {
                redirect,
                loginUrl = undefined,
                ...newParams
            } = response;
            const resolvedRedirect = redirect || (event.type === AuthEventType.LOGIN ? "/" : event.type === AuthEventType.LOGOUT ? "/login" : undefined);

            if ([AuthEventType.LOGIN, AuthEventType.CHANGE_ACCOUNT].includes(event.type)) {
                postNotification({event: NotificationEventType.HIDE, display: NotificationDisplayType.DIALOG});
                postNotification({event: NotificationEventType.HIDE, display: NotificationDisplayType.TOAST});
            }

            let originSettings = {};
            if (event.type === AuthEventType.LOGIN) {
                originSettings = {
                    origin: null,
                    previousOrigin: null
                };
            } else if (eventOrigin && resolvedRedirect && resolvedRedirect !== eventOrigin && eventOrigin !== globalParams?.origin) {
                originSettings = {
                    origin: eventOrigin,
                    previousOrigin: event.type === AuthEventType.CHANGE_ACCOUNT ? "/" : globalParams?.origin || null
                };
            }

            updateParams({
                ...newParams,
                loginUrl,
                ...originSettings
            });

            if (resolvedRedirect) {
                performRedirect(resolvedRedirect);
            }
        }).catch((error: any) => {
            const {
                message = undefined,
                redirect = undefined,
                loginUrl = undefined,
                ...newParams
            } = typeof error === "object" ? error : {message: typeof error === "string" ? error : "cuda.auth.signInError"};
            const resolvedRedirect = redirect || (event.type === AuthEventType.CHECK ? "/login" : undefined);

            let originSettings = {};
            if (eventOrigin && resolvedRedirect && resolvedRedirect !== eventOrigin && eventOrigin !== globalParams?.origin) {
                originSettings = {
                    origin: eventOrigin,
                    previousOrigin: globalParams?.origin || null
                };
            }

            updateParams({
                ...newParams,
                loginUrl,
                ...originSettings
            });

            if (message && [AuthEventType.LOGIN, AuthEventType.CHANGE_ACCOUNT].includes(event.type)) {
                postNotification({
                    event: NotificationEventType.SHOW,
                    display: NotificationDisplayType.TOAST,
                    params: {
                        content: message,
                        level: NotificationLevel.ERROR
                    }
                });
            }
            if (resolvedRedirect) {
                performRedirect(resolvedRedirect);
            } else if (event.type === AuthEventType.ERROR) {
                postEvent?.({type: AuthEventType.LOGOUT});
            }
        });
    }, [authClient, globalParams]);

    useCustomEvents<AuthEvent>(AuthEventKey, eventHandler);

    return null;
};