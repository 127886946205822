import {Button, Dialog, DialogContent, Snackbar, SnackbarContent, Typography} from "@barracuda-internal/bds-core";
import React from "react";
import {useTranslation} from "react-i18next";
import StatusBanner from "../../layout/StatusBanner/StatusBanner";
import Toolbar from "../../layout/Toolbar/Toolbar";
import DialogBody from "../DialogBody/DialogBody";
import {
    NotificationDisplayType,
    NotificationEventType,
    NotificationLevel,
    ToastNotificationParams,
    useManageNotifications,
    usePrevious
} from "../../../hooks";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";

const styles = (theme: Theme) => createStyles({
    title: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main
    },
    closeIcon: {
        color: theme.palette.common.white,
        float: "right"
    },
    dialogContent: {
        textAlign: "center",
        padding: 16
    },
    [NotificationLevel.INFO]: {
        backgroundColor: theme.palette.primary.main
    },
    [NotificationLevel.ERROR]: {
        backgroundColor: theme.palette.error.main
    },
    [NotificationLevel.WARNING]: {
        backgroundColor: theme.palette.warning.main
    },
    [NotificationLevel.OK]: {
        backgroundColor: theme.palette.success.main
    }
});
const useStyles = makeOverrideableStyles("NotificationDialog", styles);


export interface NotificationDialogProps extends StyledComponentProps<typeof styles> {
}

/**
 * Displays notifications from the [notification](/?path=/docs/cudareactapp-notifications--notifications) redux flow. Renders Status banner / toast / dialog, or nothing, depending on active notifications.
 *
 * This component is rendered as a part of the [AppLayout](/?path=/docs/core-components-layout-applayout--app-layout) component, which it itself is rendered as a core part of the [CudaReactApp](/?path=/docs/core-components-cudareactapp--cuda-react-app).
 * There is no need to add this component yourself, unless you are making use of the notification Reducer without the full [CudaReactApp](/?path=/docs/core-components-cudareactapp--cuda-react-app).
 */
export const NotificationDialog = (props: NotificationDialogProps) => {
    const classes = useStyles(props);
    const [translate] = useTranslation();
    const [notifications, postNotification] = useManageNotifications();

    const handleRequestClose = (display: NotificationDisplayType, content?: string, onDismiss?: () => void) => () => {
        postNotification({
            event: NotificationEventType.HIDE,
            display,
            params: {content}
        });
        onDismiss?.();
    };

    const toastContents = (notifications[NotificationDisplayType.TOAST]).map((notification) => notification.content);
    const closedToastNotifications = (usePrevious(notifications[NotificationDisplayType.TOAST]) || [])
        .filter((notification) => !toastContents.includes(notification.content))
        .map((notification) => ({...notification, closed: true}));
    const dialogNotification = notifications[NotificationDisplayType.DIALOG][0];
    const dialogContent = Array.isArray(dialogNotification?.content) ? dialogNotification.content : [dialogNotification?.content || ""];

    return (
        <React.Fragment>
            {notifications[NotificationDisplayType.BANNER].map((bannerNotification) => (
                <StatusBanner
                    key={bannerNotification.content}
                    open={true}
                    onClose={handleRequestClose(NotificationDisplayType.BANNER, bannerNotification.content, bannerNotification.onDismiss)}
                    dismissible={bannerNotification.dismissible}
                    classes={bannerNotification.level && {banner: classes[bannerNotification.level]}}
                    spinner={bannerNotification.spinner}
                >
                    <span
                        dangerouslySetInnerHTML={{
                            __html: translate(
                                bannerNotification.content || "",
                                bannerNotification.translateParams
                            )
                        }}
                    />
                </StatusBanner>
            ))}
            {dialogNotification && (
                <Dialog
                    open
                    bdsDialogWidth="small"
                    onClose={handleRequestClose(NotificationDisplayType.DIALOG)}
                >
                    <DialogBody
                        title="cuda.notification.title"
                        onClose={handleRequestClose(NotificationDisplayType.DIALOG)}
                    >
                        <DialogContent className={classes.dialogContent}>
                            {dialogContent.map((content, index) => (
                                <Typography component="p" key={index}>
                                    {translate(content, dialogNotification.translateParams)}
                                </Typography>
                            ))}
                        </DialogContent>
                        <Toolbar>
                            <Button
                                onClick={handleRequestClose(NotificationDisplayType.DIALOG)}
                                bdsType="interactiveEmphasis"
                                variant="contained"
                                size="small"
                            >
                                {translate("cuda.notification.ok")}
                            </Button>
                        </Toolbar>
                    </DialogBody>
                </Dialog>
            )}
            {[...notifications[NotificationDisplayType.TOAST], ...closedToastNotifications].map((notification: ToastNotificationParams & {
                closed?: boolean
            }, index: number) => (
                <Snackbar
                    key={notification && (notification.content)}
                    open={index === 0 && !notification.closed}
                    onClose={notification && handleRequestClose(NotificationDisplayType.TOAST, notification.content)}
                    autoHideDuration={notification && notification.duration}
                    className={notification.level && classes[notification.level]}
                    anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                >
                    <SnackbarContent
                        className={notification.level && classes[notification.level]}
                        message={notification.content && translate(notification.content, notification.translateParams)}
                    />
                </Snackbar>
            ))}
        </React.Fragment>
    );
};

export default NotificationDialog;