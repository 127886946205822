import {Button} from "@barracuda-internal/bds-core";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import Toolbar from "../../layout/Toolbar/Toolbar";
import SaveButton, {SaveButtonProps} from "../SaveButton/SaveButton";
import {createStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import {useFormContext} from "react-hook-form";
import {FormPropsContext} from "../FormProvider/FormProvider";

const styles = (theme: Theme) => createStyles({
    formButton: {},
    buttonContainer: {
        paddingBottom: "12px",
        paddingRight: "16px",
        display: "inline-flex",
        flexDirection: "row-reverse",
        "&> *:not(:first-child)": {
            marginRight: "12px"
        },
        alignItems: "end"
    },
    migButtonContainer: {
        display: "flex",
        alignItems: "baseline",
        minWidth: "-webkit-max-content",
        flexDirection: "column",
        justifyContent: "space-evenly",
    }
});
const useStyles = makeOverrideableStyles("FormButtonToolbar", styles);

export interface FormButtonToolbarProps extends SaveButtonProps, StyledComponentProps<typeof styles> {
    /** if true, the buttons are rendered without a Toolbar wrapping them */
    buttonsOnly?: boolean;
    /** Custom buttons to display along with the default ones */
    customButtons?: JSX.Element[];
    isCustom?: boolean;
}

/**
 FormButtonToolbar
 *
 It is a simple toolbar that stays under the form. By default, it has the Save button, but can also have Reset (if reset is true to reset the temp changes),
 Cancel (if onCancel is true), and back (if the prop back is true).
 */
const FormButtonToolbar = (props: FormButtonToolbarProps): JSX.Element => {
    const {buttonsOnly, customButtons, isCustom = false, classes: _ignored, ...saveButtonProps} = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();
    const {reset, formState: {dirtyFields}} = useFormContext();
    const {
        back,
        canReset,
        handleSubmitWithOptions: handleSubmitWithRedirect,
        disabled,
        onCancel,
        submitOnEnter
    } = useContext(FormPropsContext);
    // TODO: Had to swap from isDirty, because it was saying forms were dirty when they werent.. no idea why.
    const pristine = Object.values(dirtyFields).length === 0;

    const toolbarContent = (
        <React.Fragment>
            {!isCustom && (
                <SaveButton {...{disabled, pristine, handleSubmitWithRedirect, submitOnEnter, ...saveButtonProps}}/>)}
            {canReset && reset && (
                <Button
                    onClick={() => reset()}
                    disabled={pristine || disabled}
                    variant="contained"
                    bdsType="interactiveNeutral"
                    size="small"
                    className={classes.formButton}
                >
                    {translate("cuda.buttons.reset")}
                </Button>
            )}
            {onCancel && (
                <Button
                    onClick={onCancel}
                    disabled={disabled}
                    variant="contained"
                    bdsType="interactiveNeutral"
                    size="small"
                    className={classes.formButton}
                >
                    {translate("cuda.buttons.cancel")}
                </Button>
            )}
            {back && (
                <Button
                    onClick={back}
                    disabled={disabled}
                    variant="contained"
                    bdsType="interactiveNeutral"
                    size="small"
                    className={classes.formButton}
                >
                    {translate("cuda.buttons.back")}
                </Button>
            )}
            {/* Render custom buttons if provided */}
            {customButtons}
        </React.Fragment>
    );

    return buttonsOnly ? (
        <div className={isCustom ? classes.migButtonContainer : classes.buttonContainer}>
            {/* Render toolbar content for custom buttons */}
            {toolbarContent}
        </div>

    ) : (
        <Toolbar>
            {toolbarContent}
        </Toolbar>
    );
};

export default FormButtonToolbar;