import {Card as BdsCard, CardContent, CardHeader} from "@barracuda-internal/bds-core";
import classNames from "classnames";
import React, {Children, ReactElement, ReactNode} from "react";
import {useTranslation} from "react-i18next";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import ErrorBoundary from "../../functional/ErrorBoundary/ErrorBoundary";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";

const styles = (theme: Theme) => createStyles({
    root: {
        margin: "8px",
        width: `calc(100% - 16px)`,
        textAlign: "left",
        display: "inline-block",
        verticalAlign: "top",
        maxWidth: "100%"
    },
    cardHeader: {
        textAlign: "left",
        alignItems: "flex-start",
        backgroundColor: theme.palette.background.paper,
        padding: "8px 16px",
        overflowY: "visible"
    },
    titleTypography: {
        fontSize: "24px !important",
        fontWeight: 300,
        overflowY: "visible"
    },
    cardContent: {
        minWidth: `100%`,
        padding: 0,
        "&:last-child": {
            paddingBottom: 0
        },
        overflowX: "hidden",
        overflowY: "auto"
    }
});
const useStyles = makeOverrideableStyles("Card", styles);

export interface CardProps extends StyledComponentProps<typeof styles> {
    /** the action to display for the card */
    action?: ReactNode,
    /** the content of the card */
    children?: ReactElement | ReactElement[],
    /** classname to be added to the root element */
    className?: string,
    /** optionally render the children within the provided container element. If not provided, children are rendered directly under the CardContent element */
    containerElement?: ReactElement,
    /** html id for the root Card element */
    id: string,
    /** the title to display for the card */
    title?: string | ReactNode
    /** not used by the card itself, but used to align the card when used inside of Columns or Dashboard component. */
    column?: number
}

/**
 * A basic card (with optional title), that renders the given children as its content.
 */
export const Card = (props: CardProps): JSX.Element => {
    const {action, children, id, containerElement, className, title} = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();

    return (
        <BdsCard className={classNames(classes.root, className)} id={id}>
            {(title) && (
                <ErrorBoundary>
                    <CardHeader
                        action={action}
                        title={typeof title === "string" ? translate(title) : title}
                        className={classes.cardHeader}
                        titleTypographyProps={{className: classes.titleTypography}}
                    />
                </ErrorBoundary>
            )}
            <ErrorBoundary>
                <CardContent className={classes.cardContent}>
                    {containerElement ? React.cloneElement(containerElement, {
                        children: Children.toArray(children)
                            .filter((child) => !!child)
                            // @ts-ignore
                            .map((child , index) => React.cloneElement(child, {key: "card-item-" + index}))
                    }) : Children.toArray(children)
                        .filter((child) => !!child)
                        // @ts-ignore
                        .map((child, index) => React.cloneElement(child, {key: "card-item-" + index}))}
                </CardContent>
            </ErrorBoundary>
        </BdsCard>
    );
};

Card.defaultProps = {
    id: "cuda-generic-card"
};

export default Card;