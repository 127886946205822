import {FormControl, FormHelperText} from "@barracuda-internal/bds-core";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import React from "react";
import {formatErrorMessage, getRawError} from "../../../utils";
import InputLabel, {InputLabelProps} from "../InputLabel/InputLabel";
import {Controller} from "react-hook-form";
import {createStyles} from "@mui/styles";
import {InputWatchSettings, useInputWatch} from "../../../hooks";

const styles = createStyles({
    field: {
        maxWidth: "100%"
    }
});
const useStyles = makeOverrideableStyles("Input", styles);

interface BaseInputProps<InputComponent extends React.ElementType> extends StyledComponentProps<typeof styles>,
    Pick<InputLabelProps, | "description" | "isVarHeight" | "label" | "minimised">, InputWatchSettings {
    /**
     * an additional string to show on the bottom right side of an input field.
     */
    additionalInfoLabel?: string | {i18nKey: string, values: any},
    /**
     * an additional string to show on the right side of an input field.
     */
    additionalRightLabel?: string | {i18nKey: string, values: any},
    /**
     * additional props to provide to the InputLabel component
     */
    inputLabelProps?: InputLabelProps,
    /**
     * a dot-notation path to the value in the formContext objects that should be used as the identifying "value" of the input.
     */
    source: string,
    /**
     * if true, the input will not be wrapped in InputLabel
     */
    noLabel?: boolean,
    /**
     * the input component to render. All additional props are passed to this component on render.
     */
    component: InputComponent,
    /**
     * if true, input will be hidden with css only. It will still be rendered and registered with react-hook-form.
     */
    hideInput?: boolean,
    /**
     * name of the prop to pass as 'onChange' method. By default set to 'onChange'.
     */
    onChangeProp?: string,
    /**
     * name of the prop to pass as 'value' method. By deafult set to 'value'.
     */
    valueProp?: string,
    /**
     * if true, errors will be handled by the FormControl wrapper.
     */
    displayError?: boolean,
    /**
     * if true, any provided errors will still be displayed, even when input is disabled.
     */
    showErrorOnDisabled?: boolean,
    /**
     * data of the siblings of the component.
     */
    siblingData?: any,
    /**
     * class overrides for the provided input component.
     */
    inputClasses?: React.ComponentProps<InputComponent>["classes"],
    /**
     * additional props (or prop overrides) to pass to the input Component.
     */
    inputProps?: Partial<React.ComponentProps<InputComponent>>,
    /**
     * whether to display labels as per the new style i.e. bold
     */
    newStyle?: boolean
}

export type InputProps<InputComponent extends React.ElementType> = BaseInputProps<InputComponent>
    & Omit<React.ComponentProps<InputComponent>, keyof BaseInputProps<InputComponent> | "value" | "error" | "onChange" | "onBlur" | "onFocus" | "id">

/**
 * Creates a form connected input, wrapped in the [InputLabel](/?path=/docs/core-components-inputs-inputlabel--input-label) component.
 *
 * These inputs will work seamlessly under any form based components, such as [Form](/?path=/docs/core-components-forms-form--form),
 * [TabbedForm](/?path=/docs/core-components-forms-tabbedform--tabbed-form) and [Wizard](/?path=/docs/core-components-wizard-wizard--wizard).
 *
 * All additional props not detailed below are passed to the designated "component" on render.
 */
export const Input = <InputComponent extends React.ElementType>(props: InputProps<InputComponent>) => {
    const {
        additionalInfoLabel,
        additionalRightLabel,
        description,
        disable,
        disabled,
        displayError,
        hide,
        hideInput,
        isRequired,
        isVarHeight,
        label,
        noLabel,
        onChangeProp,
        require,
        showErrorOnDisabled,
        source,
        sourcePrefix,
        component: Component,
        validate,
        valueProp,
        inputLabelProps,
        inputClasses,
        inputProps,
        newStyle,
        ...additionalProps
    } = props;
    const classes = useStyles(props);

    const {
        inputSource,
        inputValidation,
        inputIsRequired,
        inputIsDisabled,
        inputIsHidden
    } = useInputWatch(source, {
        sourcePrefix,
        disabled,
        disable,
        hide,
        isRequired,
        require,
        validate
    });

    return !inputIsHidden ? (
        <Controller
            name={inputSource}
            rules={{validate: inputValidation}}
            render={({
                         field: {onChange, onBlur, value, name},
                         fieldState: {error}
                     }) => {
                const errorToDisplay = (!inputIsDisabled || showErrorOnDisabled) && getRawError(error);
                const field = (
                    <FormControl
                        component="fieldset"
                        error={displayError && !!error}
                        disabled={inputIsDisabled}
                        className={classes.field}
                    >
                        {React.createElement(Component, {
                            onBlur,
                            [onChangeProp || "onChange"]: (eventOrValue: any | {
                                target: { value: any }
                            }) => onChange(eventOrValue?.target ? eventOrValue.target.value : eventOrValue),
                            [valueProp || "value"]: value,
                            error: errorToDisplay || undefined,
                            id: source.replace(/[.]/g, "-"),
                            source: name,
                            disabled: inputIsDisabled,
                            classes: inputClasses,
                            ...additionalProps,
                            ...inputProps
                        })}
                        {!!errorToDisplay && displayError && (
                            <FormHelperText>{formatErrorMessage(errorToDisplay)}</FormHelperText>
                        )}
                    </FormControl>
                );

                return noLabel ? field : (
                    <InputLabel
                        additionalInfoLabel={additionalInfoLabel}
                        additionalRightLabel={additionalRightLabel}
                        description={description}
                        isRequired={inputIsRequired}
                        isVarHeight={isVarHeight}
                        hideInput={hideInput}
                        label={label}
                        newStyle={newStyle}
                        {...(inputLabelProps || {})}
                    >
                        {field}
                    </InputLabel>
                );
            }}
        />
    ) : null;
};

Input.defaultProps = {
    onChangeProp: "onChange",
    valueProp: "value",
    displayError: true
};

export default Input;