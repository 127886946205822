import {format, isValid, parseISO, Locale} from "date-fns";

/**
 * Converts an ISO datetime string into a desired human-readable format. Use "date-fns" to parse and format the date.
 *
 * If date-fns fails to parse the provided date, an empty string will be returned.
 *
 * @function
 * @param {string} dateText the ISO date-time string to format.
 * @param {string} formatString the format to use. Default is "yyyy-MM-dd HH:mm"
 * @param locale the date-fns locale to use for formatting.
 * @returns {string} the formatted date time string.
 */
export const formatDateTime = (dateText?: string, formatString: string = "yyyy-MM-dd HH:mm", locale?: Locale) => {
    if (dateText) {
        const date = parseISO(dateText);

        if (isValid(date)) {
            return format(date, formatString, {locale});
        }
    }
    return "";
};

/**
 * Formats an ISO datetime string to a single date string of the format yyyy-MM-dd.
 *
 * Uses {@link formatDateTime} to perform the parsing and formatting.
 *
 * @function
 * @param {string} dateText the ISO date-time string to format.
 * @returns {string} the formatted date string.
 */
export const formatDate = (dateText?: string) => dateText ? formatDateTime(dateText, "yyyy-MM-dd") : "";

/**
 * Converts a large integer number to a human-readable string, e.g. from 123456789 to 123,456,789.
 *
 * @function
 * @param {number} num the number to format as a string.
 * @returns {string} the formatted string.
 */
export const formatNumber = (num?: number | string) => num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : "";

/**
 * Removes all <style>...</style> blocks from a html string. Useful to remove embedded styling in a HTML string to be rendered, so that the native theme
 * styling is inherited.
 *
 * @function
 * @param {string} inputString the html string to parse.
 * @returns {string} the processed string.
 */
export const removeStyleTags = (inputString: string) => inputString ? inputString.replace(/<style([\s\S]+?)<\/style>/g, "") : "";