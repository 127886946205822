import {MutableRefObject, useEffect, useRef, useState} from "react";

export const useWidthTracking = (initialWidth = 1024, getElement?: (element: HTMLElement | null) => HTMLElement | undefined | null): [MutableRefObject<any>, number] => {
    const ref = useRef<HTMLElement>(null);
    const [width, setWidth] = useState(initialWidth);
    const checkWidth = () => {
        const element = getElement ? getElement(ref.current) : ref.current;
        if (element) {
            setWidth(element?.offsetWidth);
        }
    };

    useEffect(() => {
        checkWidth();
        window.addEventListener('resize', checkWidth);
        return () => window.removeEventListener('resize', checkWidth);
    }, []);

    return [ref, width];
};